import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import axios from "axios";
import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
// import validator from 'validator';


export default function InputField() {

    var _ = require('lodash');
    
    const [btnLoaderThree, setBtnLoaderThree] = useState(false);
    const removeExtraSpace = (s) => {
        var rSpase = s.replace(/\s{2,}/g, ' ');
        return _.trimStart(rSpase);
    };

    function myGreeting() {
        setIsThankYou(false);
        setIsError(false);
        setIsThankYouTwo(false);
        setIsErrorTwo(false);
    }
    const [setIsErrorTwo] = useState(false);
    const navigate = useNavigate();

    const [setIsThankYou] = useState(false);
    const [setIsThankYouTwo] = useState(false);
    const [setIsError] = useState(false);
    let location = window.location.href;


    return (
        <div className='input_box'>
            {/* <form action="">
                <label for="fname" className='text-base font-semibold text-black pt-3.5 pb-2 block'>Your name</label>
                <input type="text" id="" name="" className='px-3 w-full h-[42px] text-base font-semibold text-black border rounded border-[#b4b4b4] border-solid focus:outline-none' />
                <label for="lname" className='text-base font-semibold text-black pt-3.5 pb-2 block'>Your company email</label>
                <input type="text" id="" name="" className='px-3 w-full h-[42px] text-base font-semibold text-black border rounded border-[#b4b4b4] border-solid focus:outline-none' />
                <button type='submit' className='text-base font-bold text-white bg-[#db5400] w-full flex rounded-full justify-center items-center mt-6 h-11 uppercase	'>book a call with us</button>
            </form> */}

                <Formik
                  initialValues={{ name:"", email: "", phone: "", message: "", pageName: location, tryNow: "Find Out More. Fill the Below Form to Get a Call Back" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Email is required";
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = "Invalid email address";
                    }

                    // if (!values.phone) {
                    //   errors.phone = 'Phone number is required';
                    // } else if ( !validator.isMobilePhone(values.phone)) {
                    //   errors.phone = 'Invalid phone number';
                    // }

                    if (!values.name || !_.trim(values.name)) {
                      errors.name = 'Name is required';
                    } else if (values.name.length > 50) {
                      errors.name = 'Must be 15 characters or less';
                    } else if (values.name.match("[<>]")) {
                      errors.name = 'Please provide a valid name';
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setBtnLoaderThree(true);
                    setSubmitting(false);
                    axios
                      .post(`/quickContact`, values)
                      .then(function (response) {
                        // console.log(response);
                        navigate('/thank-you');
                      })
                      .catch(function (error) {
                        // console.log(error)
                        setIsErrorTwo(
                          error?.message ? error.message : "Network Error"
                        );
                      })
                      .finally(() => {
                        setBtnLoaderThree(false);
                        setTimeout(myGreeting, 5000);
                        values.email = "";
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                  }) => (

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label for="fname" className='text-base font-semibold text-black pt-3.5 pb-2 block'>Your Name</label>
                          <input type="text" onChange={handleChange} onBlur={handleBlur} name="name" value={removeExtraSpace(values.name)} autoCapitalize="none" autoComplete="new-password" maxLength="30" size="20" placeholder="Your Name" className='form-control px-3 w-full h-[42px] text-base font-semibold text-black border rounded border-[#b4b4b4] border-solid focus:outline-none'/>
                          {touched.name && errors.name ? <div style={{color: '#ff0000'}}>{errors.name}</div> : null}
                        </div>
                        <div className="form-group">
                          <label for="fname" className='text-base font-semibold text-black pt-3.5 pb-2 block'>Your Email</label>
                          <input type="text" onChange={handleChange} onBlur={handleBlur} name="email" value={removeExtraSpace(values.email)} autoCapitalize="none" autoComplete="new-password" maxLength="30" size="20" placeholder="Email Address" className='form-control form-control px-3 w-full h-[42px] text-base font-semibold text-black border rounded border-[#b4b4b4] border-solid focus:outline-none'/>
                          {touched.email && errors.email ? <div style={{color: '#ff0000'}}>{errors.email}</div> : null}
                        </div>
                        <div className="form-group">
                        {/* <PhoneInput 
                          name="phone"
                          placeholder="Enter phone number"
                          international
                          defaultCountry="IN"
                          autoCapitalize="none" autoComplete="new-password"
                          countryCallingCodeEditable={false}
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={(val) => setFieldValue('phone', val)}/> */}
                          {/* <input type="text" onChange={handleChange} onBlur={handleBlur} name="name" value={values.name} className="form-control" maxlength="80" size="20" placeholder="Phone Number" /> */}
                          {touched.phone && errors.phone ? <div style={{color: '#ff0000'}}>{errors.phone}</div> : null}
                        </div>
                        {/* <div className="form-group">
                          <textarea onChange={handleChange} onBlur={handleBlur} name="message" value={removeExtraSpace(values.message)} className="form-control" rows="3" placeholder="Your Message"></textarea>
                          {touched.message && errors.message ? <div style={{color: '#ff0000'}}>{errors.message}</div> : null}
                        </div> */}
                        <div className="submit_btn">
                          <button type="submit" className='text-base font-bold text-white bg-[#ff662a] w-full flex rounded-full justify-center items-center mt-6 h-11 uppercase hover:bg-[#e14b10]' name="contactBtn">
                            <strong> BOOK A FREE CONSULTATION</strong>
                            {btnLoaderThree && (
                              <span className="btn-ring-animation"></span>
                            )}
                          </button>
                        </div>
                    </form>
                  )}

                </Formik>
        </div>
    )
}