import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import axios from 'axios';

const ApiConfig = {
  "LocalURL" :"https://reddensoft.us",
  "ServerURL" :"https://reddensoft.us"
}
// https://works-reddensoft.com/reddensite/
// https://works-reddensoft.com

let URL;
if (window.location.hostname === "localhost") {
  URL = `${ApiConfig.LocalURL}/admin/api`;
} else {
  URL = `${ApiConfig.ServerURL}/admin/api`;
}

axios.defaults.baseURL = URL;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);


