import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from "axios";


export default function CaseStudies() {

    async function PortfolioApi() {
        axios.get('/portfoliodata')
       .then(await function  (response) {
           if(response && response?.data && response?.data?.data){
               setPortData(response?.data?.data)
           }
       })
       .catch(await function (error) {
           console.log(error)
       })
       .finally(() => {
         console.log('Experiment completed');
         setIsLoader(false);
       }); 
    }

    useEffect(() => {
        setIsLoader(true);
        PortfolioApi();
    }, []);

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [portData, setPortData] = useState();
    const [isLoader, setIsLoader] = useState(false);

    var projectSliderNav = {
        asNavFor:nav1, 
        ref: (slider2) => setNav2(slider2),
        slidesToShow:5,
        slidesToScroll: 1,
        autoplay: false,
        arrows:false,
        speed: 1000,
        autoplaySpeed: 3000,
        swipeToSlide:true,
        focusOnSelect:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                }
              }
        ]
      };
    
    var projectSlider = {
        asNavFor: nav2,
        ref:(slider1) => setNav1(slider1),
        fade:true,
        arrows:false,
    };

    return (
        <section className="section_three pt-20">
            <div className="container m-auto">
                <div className="text_head_part">
                    <h2 className="text-5xl font-bold text-black text-center">Recent <span className="text-[#f75a1c]"> Case Studies </span></h2>
                    <p className="text-[#515151] font-normal text-lg py-5 text-center">We’ve hand-picked some case studies we think you’ll like:</p>
                </div>
            </div>

            <div className="m-auto">

                {isLoader && !portData && (
                    <div className="section-loader">
                        <div className="loader--ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    <p>Loading...</p>
                    </div>
                )}
                
                {portData ? (
                    <>
                    <div className="blue_bar mt-7 mb-20 bg-[#e4f1f7] border-y border-solid border-[##b0cae1]">
                        <div className="container m-auto">
                            <Slider {...projectSliderNav}>

                                {portData && portData?.length > 0 && portData?.map((items, i) => {
                                    return (
                                        <div key={i} className="p-5 m-auto ease-in-out hover:bg-white">
                                            <span className="small_img">
                                                <img className="m-auto" src={items.logo} alt="" />
                                                <h5 className="text-xs font-semibold text-[#949eb1] pt-2.5 text-center">{items.title}</h5>
                                            </span>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                    <div className="container m-auto">
                        <div className="sec_inner_port_holder">
                            <Slider {...projectSlider}>
                                {portData && portData?.length > 0 && portData?.map((items, i) => {
                                return(
                                        <div key={i}>
                                            <div className="sec_inner_port flex">
                                                <div className="left">
                                                    <div className="laps">
                                                        <img src={items?.image} alt="" />
                                                    </div>
                                                </div>
                                                <div className="right pl-12">
                                                    <ul className="mt-5">
                                                        <li className="text-sm font-bold text-[#515151] py-2">
                                                            <span className="pb-1.5 text-lg font-semibold text-[#f0612e] block">Project Name:</span> 
                                                            {items?.title}</li>
                                                        <li className="text-sm font-bold text-[#515151] py-2"></li>
                                                        <li className="text-sm font-bold text-[#515151] py-2">
                                                            <span className="pb-1.5 text-lg font-semibold text-[#f0612e] block">Technology :</span> 
                                                            {items?.technology && JSON.parse(items?.technology)?.length > 0 && JSON.parse(items?.technology)?.reverse().map((technology, t) => {
                                                            return (<> { technology.nm} {t + 1 === JSON.parse(items?.technology)?.length ? '':'  |'}</>)
                                                        })}</li>
                                                        
                                                    </ul>
                                                    <div className="text-lg font-medium text-[#515151] pt-6 pb-4 max-w-[540px] mb-5" dangerouslySetInnerHTML={{__html: items?.description}}></div>
                                                    <Link className="text-sm font-semibold bg-[#f75a1c] border rounded-full border-[#f75a1c] border-solid py-3 px-11 text-white hover:bg-[#fff] hover:border[#515151] hover:text-[#515151] inline-block	">Read details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )  
                                })}
                            </Slider>
                        </div>
                    </div>
                    </>
                    ):(!isLoader ? (
                    <div className="container">
                        <div style={{textAlign: 'center',}}>
                            <span style={{margin: '10px auto'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" width={'40'} height={'40'}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>
                            </span>

                            <p>Case Studies not found!</p>
                        </div>
                    </div>
                    ):(null)    
                )}
            </div>

        </section>
    )
}