import React from "react";
import {casestudymodalimage} from '../../Assets';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function CaseStudiesModal({toggleModal})  {


    return (
        <div className="moadal_index fixed top-0 left-0 right-0 bottom-0">
            <div onClick={toggleModal} className="fixed top-0 left-0 right-0 bottom-0 bg-black/70 blur-3xl"></div>
            <div className="fixed bg-white max-w-[864px] w-full rounded-md top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <div className="grid grid-cols-3">
                    <div className="left bg-gradient-to-b from-[#d3071a] to-[#0368b5] pt-[59px] px-3.5 rounded-l-md">
                        <div className="text">
                            <h3 className="text-5xl font-semibold text-white my-2.5">Start Your</h3>
                            <h4 className="text-2xl font-medium text-white py-2.5 mt-5 mb-2.5 border-y border-[#ffffff66] border-solid">Free-Trial Now</h4>
                        </div>
                        <div className="image mt-24 mb-14 relative">
                            <span className="w-[157px] h-[157px] bg-[#c9273e] rounded-full relative m-auto z-0 flex"></span>
                            <img className="m-auto z-10 absolute left-0 right-0 bottom-[30px]" src={casestudymodalimage} alt="icon" />
                        </div>
                    </div>
                    <div className="right col-span-2 relative">
                        <div className="box">
                            <div className="heading mt-8">
                                <h2 className="text-3xl font-medium text-black text-center">Please fill up the form below</h2>
                            </div>
                            <div className="grid grid-cols-2 pt-10 px-7 pb-7">
                                <div className="label_input pb-7 px-3.5">
                                    <label for='' className="text-base font-medium text-black block">Name*</label>
                                    <input type="text" placeholder="Enter your name" className="h-9 w-full text-base font-normal text-[#757575] border-b border-black border-solid focus:outline-none " />
                                </div>
                                <div className="label_input phone_input pb-7 px-3.5">
                                    <label for='' className="text-base font-medium text-black block">Phone*</label>
                                        {/* <PhoneInput className="h-9 w-full text-base font-normal text-[#757575] border-x-0 border-b border-black border-solid focus:outline-none "
                                            inputProps={{
                                                name: 'phone',
                                                country: 'us',
                                                required: true,
                                                autoFocus: true,
                                            }}
                                        /> */}

                                        <PhoneInput 
                                                country={"us"}
                                                value="1425652"
                                                onChange={phone => console.log({ phone })}
                                        />
                                </div>
                                <div className="label_input px-3.5">
                                    <label for='' className="text-base font-medium text-black block">Email*</label>
                                    <input type="text" placeholder="emailid@example.com" className="h-9 w-full text-base font-normal text-[#757575] border-b border-black border-solid focus:outline-none " />
                                </div>
                                <div className="label_input px-3.5">
                                    <label for='' className="text-base font-medium text-black block">Website (If any)</label>
                                    <input type="text" placeholder="www.website.com" className="h-9 w-full text-base font-normal text-[#757575] border-b border-black border-solid focus:outline-none " />
                                </div>
                            </div>
                            <div className="textarea px-11">
                                <label for='' className="text-base font-medium text-black block">Message</label>
                                <textarea placeholder="" className="h-14 w-full text-base font-normal text-[#757575] border-b border-black border-solid focus:outline-none resize-none"></textarea>
                            </div>
                            <div className="submit_btn px-11 pt-12 flex justify-end">
                                <button type="submit" className="flex items-center text-base font-medium text-white bg-black rounded-full py-[11px] px-[30px]">
                                    Submit 
                                    <span className="ml-1.5">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path></svg>
                                    </span>
                                </button>
                            </div>
                            <div className="close_btn absolute top-0.5 right-0.5">
                                <button onClick={toggleModal} className="w-[30px] h-[30px] text-[26px] font-medium text-white bg-gradient-to-r from-[#d3071a] to-[#0368b5] flex justify-center items-center rounded-tr-[6px]">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" font-size="19" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}