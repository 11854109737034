import React from 'react';
import { Route , Routes } from 'react-router-dom'
import HomePage from '../Pages/HomePage/index';

export default function Router()  {

    return  (

        <Routes>
            <Route path='/' element={ <HomePage />} />
        </Routes>
    )
}